export const content = {
	title: "Chứng nhận, Hồ sơ công bố và Bản kiểm nghiệm các sản phẩm The Thanh Coffee",
	subtitle: `
    <p>
       Chúng tôi nỗ lực để các sản phẩm đáp ứng các tiêu chuẩn cao nhất của ngành về mức độ an toàn theo tiêu chuẩn Việt Nam và quốc tế. Chúng tôi làm mọi điều có thể để mang tới cho người tiêu dùng lựa chọn tối ưu và an tâm trong khi sử dụng.
    </p>
    `,
	children: [
		{
			title: "Chứng nhận vệ sinh an toàn thực phẩm",
			subtitle: "",
			children: [
				{
					title: "Chứng nhận vệ sinh an toàn thực phẩm",
					subtitle: `
                        <p>
                            Chúng tôi đáp ứng các tiêu chuẩn an toàn vệ sinh thực phẩm theo tiêu chuẩn quy định của nhà nước Việt Nam.

                        </p>
                        <p>
                            <br>
                            Chúng tôi tự chủ trong việc làm đá viên với máy làm đá từ Manitowoc Ice, nguồn nước từ hãng lọc nước số 1 Châu Âu - BWT.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <a
                                title="Bấm v&agrave;o th&ecirc;m/ chỉnh sửa link để thay đổi text được hiển thị"
                                href="/certificates/Giấy An toàn Vệ sinh Thực phẩm.pdf"
                                target="_blank"
                                rel="noopener"
                            >
                                Xem giấy chứng nhận&nbsp;
                                &gt;
                            </a>
                        </p>
                    `,
					images: [],
				},
				{
					title: "Bao bì cốc nhựa",
					subtitle: `
                        <p>
                            Cốc nhựa nhập khẩu của chúng tôi dùng cho đồ uống lạnh containing 100% recycled PET đáp ứng các tiêu chuẩn cao nhất về chất lượng và mức độ an toàn khi sử dụng cho thực phẩm và hiệu suất thuận tiện trong khi sử dụng.
                        </p>
                        <br>
                        <p>
                            Cốc được sản xuất theo công nghệ & tuân thủ các tiêu chuẩn bền vững Châu Âu và được sử dụng tại các hãng F&B lớn hàng đầu thế giới: 
                        </p>
                        <p>
                            <a
                                title="Bấm v&agrave;o th&ecirc;m/ chỉnh sửa link để thay đổi text được hiển thị"
                                href="/certificates/Hồ sơ tự công bố.pdf"
                                target="_blank"
                                rel="noopener"
                            >
                                Xem hồ sơ công bố&nbsp;
                                &gt;
                            </a>
                        </p>
                        <p>
                            <a
                                title="Xem thỏa thuận"
                                href="/certificates/KQTN Cốc Nhựa.pdf"
                                target="_blank"
                                rel="noopener"
                            >
                                Xem bản kiểm nghiệm an toàn sản phẩm &gt;
                            </a>
                            </p>
                        <p>
                            <a
                                style={{ marginLeft: '16px' }}
                                href="/certificates/Bản dịch Cốc nhựa.pdf"
                                target="_blank"
                                rel="noopener"
                            >
                                Xem bản dịch chứng minh nguồn gốc
                            </a>
                        </p>
                    `,
					images: [],
				},
				{
					title: "Cốc giấy, ống hút và bao bì giấy",
					subtitle: `
                        <p>
                            Cốc giấy & ống hút của chúng tôi được làm từ PLA, loại vật liệu có thể phân hủy hoàn toàn và ủ thành phân hữu cơ trong thời gian 4-6 tuần ra ngoài môi trường. 
                        </p>
                        <p>
                            Bao bì giấy cho việc mang đi của chúng tôi đồng thời được làm từ vật liệu an toàn với môi trường, với công nghệ chuyên dùng cho việc đựng thực phẩm chất lượng cao đảm bảo an toàn để dùng cho thực phẩm.
                        </p>
                         <p>
                            <a
                                title="Xem bản kiểm nghiệm an toàn sản phẩm"
                                href="/certificates/Kết quả Thử nghiệm cốc giấy.pdf"
                                target="_blank"
                                rel="noopener"
                            >
                                Xem bản kiểm nghiệm an toàn sản phẩm &gt
                            </a>
                            </p>
                        <p>
                            <a
                                style={{ marginLeft: '16px' }}
                                href="/certificates/Hồ sơ tự công bố cốc giấy.pdf"
                                rel="noopener"
                                target="_blank"
                            >
                                Xem hồ sơ công bố sản phẩm
                            </a>
                        </p>
                    `,
					images: [],
				},
				{
					title: "Dụng cụ ăn uống",
					subtitle: `
                        <p>
                            Dao, muỗng, nĩa dùng cho đồ ăn mang đi được làm từ CPLA, loại vật liệu phân huỷ sinh học & an toàn với sức khoẻ và môi trường theo tiêu chuẩn Mỹ. 
                        </p>
                        <p>
                            Chúng tôi đang từng bước xây dựng trải nghiệm để cho việc mua mang đi thuận tiện hơn mỗi ngày. 
                        </p>
                        <p>
                            <a
                                title="Xem bản kiểm nghiệm an toàn sản phẩm"
                                href="/certificates/Hồ sơ tự công bố dụng cụ ăn uống.pdf"
                                target="_blank"
                                rel="noopener"
                                >
                                Xem hồ sơ công bố &gt;
                                </a>
                                </p>
                                <p>
                                <a
                                style={{ marginLeft: '16px' }}
                                href="/certificates/Kết quả thử nghiệm dụng cụ ăn uống.pdf"
                                rel="noopener"
                                target="_blank"
                            >
                                Xem bản kiểm nghiệm an toàn sản phẩm
                            </a>
                        </p>
                    `,
					images: [],
				},
			],
		},
	],
};
