import { Box } from "@mui/material";
import React from "react";

const ExitIcon = () => {
  return (
    <Box>
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.958344 1.02936L12.6997 13.2617"
          stroke="white"
          strokeWidth="0.6435"
          strokeMiterlimit="10"
        />
        <path
          d="M12.6997 1.02936L0.958344 13.2617"
          stroke="white"
          strokeWidth="0.6435"
          strokeMiterlimit="10"
        />
      </svg>
    </Box>
  );
};

export default ExitIcon;
