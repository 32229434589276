import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
const PauseCircleIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.7705 33.9792H21.0625V15.0208H18.7705V33.9792ZM27.9376 33.9792H30.2295V15.0208H27.9376V33.9792ZM24.5088 48.875C21.1455 48.875 17.9858 48.2354 15.0296 46.9561C12.0739 45.6769 9.49109 43.9334 7.28109 41.7257C5.07109 39.5175 3.32602 36.9369 2.04589 33.9839C0.765295 31.0309 0.125 27.8726 0.125 24.5088C0.125 21.1383 0.764618 17.9693 2.04385 15.0019C3.32309 12.0349 5.06658 9.45385 7.27432 7.25875C9.48252 5.06364 12.0631 3.32602 15.0161 2.04589C17.9691 0.765295 21.1274 0.125 24.4912 0.125C27.8617 0.125 31.0307 0.76462 33.9981 2.04386C36.9651 3.32309 39.5461 5.05913 41.7412 7.25198C43.9364 9.44483 45.674 12.0236 46.9541 14.9883C48.2347 17.9526 48.875 21.1202 48.875 24.4912C48.875 27.8545 48.2354 31.0142 46.9561 33.9704C45.6769 36.9261 43.9409 39.5089 41.748 41.7189C39.5552 43.9289 36.9764 45.674 34.0117 46.9541C31.0474 48.2347 27.8798 48.875 24.5088 48.875ZM24.5 46.5831C30.6506 46.5831 35.8689 44.4385 40.1548 40.1494C44.4403 35.8599 46.5831 30.6434 46.5831 24.5C46.5831 18.3494 44.4403 13.1311 40.1548 8.84515C35.8689 4.55967 30.6506 2.41693 24.5 2.41693C18.3566 2.41693 13.1401 4.55967 8.85057 8.84515C4.56148 13.1311 2.41693 18.3494 2.41693 24.5C2.41693 30.6434 4.56148 35.8599 8.85057 40.1494C13.1401 44.4385 18.3566 46.5831 24.5 46.5831Z"
          fill="#1C1B1F"
        />
      </svg>
    </Box>
  );
};

export default PauseCircleIcon;
