import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
const NewsInstaIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="35"
        height="36"
        viewBox="0 0 35 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5207 25.5812C21.5399 25.5812 24.7967 22.3232 24.7967 18.3041C24.7967 14.285 21.5399 11.0264 17.5207 11.0264C13.5015 11.0264 10.2422 14.285 10.2422 18.3041C10.2422 22.3232 13.5015 25.5812 17.5207 25.5812Z"
          stroke="#6B6C64"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M26.6345 11.1256C25.5454 11.1256 24.7031 10.2344 24.7031 9.19482C24.7031 8.15521 25.5949 7.26465 26.6345 7.26465C27.7237 7.26465 28.566 8.15521 28.566 9.19482C28.566 10.2344 27.7237 11.1256 26.6345 11.1256Z"
          fill="white"
        />
        <path
          d="M24.3059 0.828613H10.6917C4.80046 0.828613 0 5.6308 0 11.5219V25.1359C0 31.027 4.80046 35.8286 10.6917 35.8286H24.3059C30.1971 35.8286 35 31.027 35 25.1359V11.5219C35 5.58129 30.2466 0.828613 24.3059 0.828613ZM32.0799 25.1359C32.0799 29.4429 28.5634 32.908 24.3059 32.908H10.6917C6.38465 32.908 2.92009 29.3934 2.92009 25.1359V11.5219C2.92009 7.21497 6.43416 3.74926 10.6917 3.74926H24.3059C28.6129 3.74926 32.0799 7.21497 32.0799 11.5219V25.1359Z"
          fill="#6B6C64"
        />
        <path
          d="M26.6345 11.1256C27.7009 11.1256 28.566 10.2611 28.566 9.19482C28.566 8.12852 27.7009 7.26465 26.6345 7.26465C25.5682 7.26465 24.7031 8.12852 24.7031 9.19482C24.7031 10.2611 25.5682 11.1256 26.6345 11.1256Z"
          fill="#6B6C64"
        />
      </svg>
    </Box>
  );
};

export default NewsInstaIcon;
