export const ourValueContents = [
	{
		title: "Thủ công",
		subtitle: "Tạo dựng mọi thứ từ sự chăm chút trong việc thủ công",
		desc: ["Chúng tôi thích sự nghiêm ngặt của các chi tiết - bất kể công việc của chúng tôi là gì.", "Chúng tôi học và tiếp tục cải thiện trong quá trình theo đuổi sự phát triển. Chúng tôi cung cấp sự xuất sắc với niềm đam mê và sự sáng tạo."],
	},
	{
		title: "Kết quả công việc",
		subtitle: "Chịu trách nhiệm về kết quả",
		desc: ["Chúng tôi luôn đạt được mục tiêu của mình với sự tập trung, chính trực và động lực.", "Chúng tôi tập trung vào kết quả vì chúng tôi hiểu trong mọi hành động đều có kết quả và ảnh hưởng trực tiếp tới trải nghiệm của khách hàng của chúng tôi."],
	},
	{
		title: "Can đảm",
		subtitle: "Đối mặt với sự can đảm",
		desc: ["Chúng tôi chấp nhận những cuộc trò chuyện khó khăn, với sự tôn trọng, để khiến tất cả chúng ta trở nên tốt hơn.", "Chúng tôi theo đuổi những ý tưởng táo bạo vượt ra khỏi vùng an toàn của mình.", " Chúng tôi nỗ lực làm điều đúng đắn, ngay cả khi điều đó là điều khó khăn."],
	},
	{
		title: "Thân mật",
		subtitle: "Hiểu và chấp nhận nhau.",
		desc: ["Chúng tôi chủ động lắng nghe và kết nối với sự ấm áp và minh bạch.", "Chúng tôi công nhận và trân trọng mọi người vì chính con người họ.", "Chúng tôi đối xử với nhau một cách tôn trọng và đàng hoàng."],
	},
	{
		title: "Niềm vui",
		subtitle: "Tự hào về công việc của chúng tôi và tận hưởng giá trị của khoảnh khắc",
		desc: ["Chúng tôi tự hào về công việc của mình và vui vẻ khi làm việc.", "Chúng tôi ăn mừng lẫn nhau và chiến thắng của mình.", "Chúng tôi tạo ra những rung cảm tuyệt vời để mang lại điều tốt nhất cho người khác."],
	},
];
