import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";

const AddressIconSSize = () => {
	return (
		<Box
			sx={{
				...styles.centerFlex,
			}}
		>
			<svg
				width="40"
				height="40"
				viewBox="0 0 58 57"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<mask
					id="mask0_176_40"
					style={{ maskType: "alpha" }} // Object notation for style
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="58"
					height="57"
				>
					<rect
						x="27.918"
						width="40"
						height="40"
						transform="rotate(43.1114 27.918 0)"
						fill="#D9D9D9"
					/>
				</mask>
				<g mask="url(#mask0_176_40)">
					<path
						d="M19.8616 29.4205L25.8742 31.4535L28.2989 37.3191L28.6387 37.3079L34.2 22.56L19.8504 29.0807L19.8616 29.4205ZM18.608 39.2232C17.0938 37.8056 15.9392 36.1855 15.1441 34.3628C14.3492 32.5403 13.9198 30.6749 13.8559 28.7666C13.7919 26.8583 14.0959 24.969 14.7676 23.0987C15.439 21.2285 16.4835 19.5361 17.9013 18.0217C19.3188 16.5075 20.9389 15.3529 22.7616 14.5578C24.5841 13.7629 26.4495 13.3335 28.3578 13.2696C30.2661 13.2057 32.1554 13.5096 34.0257 14.1813C35.896 14.8527 37.5883 15.8973 39.1027 17.315C40.6169 18.7325 41.7715 20.3526 42.5666 22.1753C43.3615 23.9978 43.791 25.8632 43.8549 27.7715C43.9188 29.6798 43.6148 31.5691 42.9431 33.4394C42.2717 35.3097 41.2272 37.002 39.8095 38.5164C38.3919 40.0306 36.7718 41.1852 34.9491 41.9803C33.1266 42.7753 31.2612 43.2047 29.3529 43.2686C27.4446 43.3325 25.5553 43.0285 23.685 42.3568C21.8148 41.6854 20.1224 40.6409 18.608 39.2232ZM19.7431 38.0027C22.4604 40.5466 25.6448 41.7583 29.2962 41.6379C32.9476 41.5175 36.0452 40.0986 38.589 37.3813C41.1329 34.664 42.3446 31.4796 42.2242 27.8282C42.1038 24.1768 40.6849 21.0792 37.9676 18.5354C35.2503 15.9915 32.0659 14.7798 28.4145 14.9002C24.7631 15.0206 21.6655 16.4395 19.1217 19.1568C16.5778 21.8741 15.3661 25.0585 15.4865 28.7099C15.6069 32.3613 17.0258 35.4589 19.7431 38.0027Z"
						fill="#1C1B1F"
					/>
				</g>
			</svg>
		</Box>
	);
};

export default AddressIconSSize;
