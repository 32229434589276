import React from "react";
import { Box } from "@mui/material";
import { styles } from "../../../styles";

const MessageIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="10" fill="black" />
        <path
          d="M34 18C34 16.9 33.1 16 32 16H16C14.9 16 14 16.9 14 18M34 18V30C34 31.1 33.1 32 32 32H16C14.9 32 14 31.1 14 30V18M34 18L24 25L14 18"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
};

export default MessageIcon;
