import { Box } from "@mui/material";

const PlayCircle = () => {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="222"
        height="222"
        viewBox="0 0 222 222"
        fill="none"
      >
        <path
          d="M111 203.5C162.086 203.5 203.5 162.086 203.5 111C203.5 59.9137 162.086 18.5 111 18.5C59.9137 18.5 18.5 59.9137 18.5 111C18.5 162.086 59.9137 203.5 111 203.5Z"
          stroke="#101828"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.5 74L148 111L92.5 148V74Z"
          stroke="#101828"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      ;
    </Box>
  );
};

export default PlayCircle;
