import React from "react";
import { Box } from "@mui/material";
import { styles } from "../../../styles";

const PhoneIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.666504" width="48" height="48" rx="10" fill="black" />
        <path
          d="M34.6664 28.9201V31.9201C34.6676 32.1986 34.6105 32.4743 34.4989 32.7294C34.3874 32.9846 34.2237 33.2137 34.0185 33.402C33.8133 33.5902 33.571 33.7336 33.3072 33.8228C33.0434 33.912 32.7638 33.9452 32.4864 33.9201C29.4093 33.5857 26.4534 32.5342 23.8564 30.8501C21.4403 29.3148 19.3918 27.2663 17.8564 24.8501C16.1664 22.2413 15.1147 19.2711 14.7864 16.1801C14.7614 15.9036 14.7943 15.6249 14.8829 15.3617C14.9716 15.0986 15.114 14.8568 15.3012 14.6517C15.4884 14.4466 15.7162 14.2828 15.9702 14.1706C16.2242 14.0584 16.4988 14.0004 16.7764 14.0001H19.7764C20.2617 13.9953 20.7322 14.1672 21.1002 14.4836C21.4682 14.8001 21.7085 15.2395 21.7764 15.7201C21.9031 16.6802 22.1379 17.6228 22.4764 18.5301C22.611 18.888 22.6401 19.277 22.5603 19.651C22.4806 20.0249 22.2953 20.3682 22.0264 20.6401L20.7564 21.9101C22.18 24.4136 24.2529 26.4865 26.7564 27.9101L28.0264 26.6401C28.2983 26.3712 28.6416 26.1859 29.0156 26.1062C29.3895 26.0264 29.7785 26.0556 30.1364 26.1901C31.0437 26.5286 31.9864 26.7635 32.9464 26.8901C33.4322 26.9586 33.8758 27.2033 34.193 27.5776C34.5101 27.9519 34.6786 28.4297 34.6664 28.9201Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
};

export default PhoneIcon;
