import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../styles";
import NavBar from "../../component/layout/NavBar";
import Layout from "../../component/layout";
const Mobile = () => {
    return (
        <Box sx={{ ...styles.fontSize13, "div>p": { margin: 0 } }}>
            <NavBar />
            <Layout.MainBg>
                <Box
                    sx={{ p: "30px 24px" }}
                    dangerouslySetInnerHTML={{
                        __html: `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;'>
          <strong>
            <span style='font-size:21px;'>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">
                  CAM KẾT VỀ HOẠT ĐỘNG
                </font>
              </font>
            </span>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;'>
          <span style='font-size:16px;'>
            &nbsp;
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">
                The Thanh Coffee cam kết mang lại tac động x&atilde; hội
                t&iacute;ch cực. Ch&uacute;ng t&ocirc;i gi&uacute;p giải quyết
                c&aacute;c quy tr&igrave;nh x&atilde; hội trong c&aacute;ch
                ch&uacute;ng t&ocirc;i tự động xử l&yacute; trong qu&aacute;
                tr&igrave;nh kinh doanh của m&igrave;nh; th&ocirc;ng tin qua
                c&aacute;c tham số của ch&uacute;ng t&ocirc;i v&agrave;o cộng
                đồng của ch&uacute;ng t&ocirc;i; v&agrave; trong c&aacute;c
                t&agrave;i khoản đầu tư m&agrave; ch&uacute;ng t&ocirc;i thực
                hiện để hỗ trợ đ&aacute;ng kể v&agrave; d&agrave;i hạn cho
                c&aacute;c vấn đề x&atilde; hội cấp b&aacute;ch.
              </font>
            </font>
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            &nbsp;
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <span style='font-size:19px;line-height:150%;'>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">
                  T&aacute;c động x&atilde; hội của c&aacute;c hoạt động của
                  ch&uacute;ng ta&nbsp;
                </font>
              </font>
            </span>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">
                Trong qu&aacute; tr&igrave;nh phục vụ kh&aacute;ch h&agrave;ng,
                c&aacute;c đồng nghiệp được mong đợi sẽ suy nghĩ rộng hơn về
                hoạt động c&ocirc;ng việc của ch&uacute;ng t&ocirc;i,
                ch&uacute;ng t&ocirc;i kết nối với nhiều b&ecirc;n li&ecirc;n
                quan của kh&aacute;ch h&agrave;ng, để gi&uacute;p kh&aacute;ch
                h&agrave;ng của t&ocirc;i lưu t&acirc;m đến t&aacute;c động
                x&atilde; hội của họ, t&iacute;ch tranh cực luận về t&aacute;c
                động của c&aacute;c khuyến nghị của ch&uacute;ng t&ocirc;i đối
                với cổ đ&ocirc;ng, kh&aacute;ch h&agrave;ng v&agrave; lợi
                &iacute;ch x&atilde; hội, v&agrave; lu&ocirc;n h&agrave;nh động
                theo c&aacute;ch ph&ugrave; hợp với c&aacute;c luật v&agrave;
                quy định c&oacute; li&ecirc;n quan. Ch&uacute;ng t&ocirc;i
                khuyến kh&iacute;ch c&aacute;c doanh nghiệp muốn đ&oacute;ng
                g&oacute;p cho cộng đồng của họ - v&iacute; dụ: bằng c&aacute;ch
                tham gia hoạt động t&igrave;nh nguyện, phục v ụ hội đồng quản
                trị phi lợi nhuận v&agrave; đ&oacute;ng g&oacute;p từ thiện. Tuy
                nhi&ecirc;n, c&aacute;c th&agrave;nh vi&ecirc;n c&ocirc;ng ty
                kh&ocirc;ng được ph&eacute;p ứng cử v&agrave;o c&aacute;c chức
                vụ ch&iacute;nh trị hoặc nhận một vai tr&ograve; ch&iacute;nh
                thức trong một chiến dịch ch&iacute;nh trị.&nbsp;
              </font>
            </font>
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <span style='font-size:19px;line-height:150%;'>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">
                  B&aacute;o c&aacute;o thời gian v&agrave; chi ph&iacute;&nbsp;
                </font>
              </font>
            </span>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">
                Ngo&agrave;i ra c&aacute;c đ&aacute;nh gi&aacute; m&agrave; The
                Thanh Coffee thực hiện thường xuy&ecirc;n để x&aacute;c minh
                t&iacute;nh x&aacute;c thực của thời gian v&agrave; chi
                ph&iacute;. Khi chi ti&ecirc;u của ch&uacute;ng t&ocirc;i
                ph&ugrave; hợp v&agrave; c&oacute; thể thực hiện cam kết tăng
                gi&aacute; trị, ch&uacute;ng t&ocirc;i lu&ocirc;n cố gắng củng
                cố mối quan hệ với kh&aacute;ch h&agrave;ng. Tu&acirc;n thủ
                ch&iacute;nh s&aacute;ch chi ph&iacute; v&agrave; b&aacute;o
                c&aacute;o chi ph&iacute; khi ch&uacute;ng t&ocirc;i ph&aacute;t
                hiện sinh học để cho ph&eacute;p kh&aacute;ch h&agrave;ng thanh
                to&aacute;n kịp thời v&agrave; đạt được kết quả t&agrave;i
                ch&iacute;nh ch&iacute;nh x&aacute;c. B&aacute;o c&aacute;o
                ch&iacute;nh x&aacute;c c&aacute;c t&agrave;i khoản để c&oacute;
                thể y&ecirc;u cầu tất cả c&aacute;c ứng dụng chi ph&iacute;
                v&agrave; tr&aacute;nh sự chậm chạp trong qu&aacute;
                tr&igrave;nh ho&agrave;n trả.&nbsp;
              </font>
            </font>
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <span style='font-size:19px;line-height:150%;'>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">
                  B&aacute;o c&aacute;o t&agrave;i ch&iacute;nh
                </font>
              </font>
            </span>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">
                Ch&uacute;ng t&ocirc;i chuẩn bị v&agrave; cung cấp c&aacute;c
                b&aacute;o c&aacute;o v&agrave; t&agrave;i liệu ch&iacute;nh
                x&aacute;c, đ&aacute;p ứng kịp thời v&agrave; đầy đủ.&nbsp;
              </font>
            </font>
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <em>
              <span style='font-size:16px;line-height:150%;'>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    C&ocirc;ng bố ch&iacute;nh x&aacute;c th&ocirc;ng tin
                    t&agrave;i ch&iacute;nh&nbsp;
                  </font>
                </font>
              </span>
            </em>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">
                Mọi người đều đ&oacute;ng một vai tr&ograve; trong chi tiết
                ch&iacute;nh x&aacute;c. Việc tiết lộ t&agrave;i ch&iacute;nh
                ch&iacute;nh của The Thanh Coffee dựa tr&ecirc;n hồ sơ Kinh
                doanh ch&iacute;nh x&aacute;c, h&oacute;a đơn của kh&aacute;ch
                h&agrave;ng, b&aacute;o c&aacute;o thời gian v&agrave; chi
                ph&iacute; cũng như số giờ l&agrave;m việc. Cho ph&eacute;p
                c&aacute;c chuy&ecirc;n gia như Quan hệ nh&agrave; đầu tư,
                Ph&aacute;p l&yacute;, T&agrave;i ch&iacute;nh v&agrave; Truyền
                th&ocirc;ng Doanh nghiệp phản hồi c&aacute;c y&ecirc;u cầu cung
                cấp th&ocirc;ng tin trong lĩnh vực chuy&ecirc;n m&ocirc;n c ủa
                họ để đảm bảo th&ocirc;ng tin li&ecirc;n lạc nhất qu&aacute;n
                v&agrave; ph&ugrave; nhảy l&ograve; c&ograve; .&nbsp;
              </font>
            </font>
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <span style='font-size:19px;line-height:150%;'>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">
                  Hồ sơ kinh doanh&nbsp;
                </font>
              </font>
            </span>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">
                Ch&uacute;ng t&ocirc;i quản l&yacute; v&agrave; xử l&yacute; hồ
                sơ kinh doanh theo thời gian biểu chuẩn của The Thanh Coffee để
                lưu giữ, t&ugrave;y thuộc v&agrave;o c&aacute;c y&ecirc;u cầu
                hợp đồng v&agrave; ph&aacute;p l&yacute; kh&aacute;c, ngoại trừ
                th&ocirc;ng b&aacute;o lưu giữ Bộ phận ph&aacute;p l&yacute;
                ph&aacute;t h&agrave;nh.&nbsp;
              </font>
            </font>
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <em>
              <span style='font-size:16px;line-height:150%;'>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    Lưu hồ sơ kinh doanh một c&aacute;ch hợp l&yacute;&nbsp;
                  </font>
                </font>
              </span>
            </em>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">
                X&aacute;c định, quản l&yacute; v&agrave; loại bỏ hồ sơ doanh
                nghiệp theo c&aacute;ch biểu thị ti&ecirc;u chuẩn của The Thanh
                Coffee để lưu trữ &mdash; v&agrave; theo c&aacute;ch tốt nhất để
                ch&uacute;ng t&ocirc;i bảo vệ dữ liệu v&agrave; th&ocirc;ng
                tin.&nbsp;
              </font>
            </font>
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            C&aacute;c khoảng thời gian lưu giữ phản &aacute;nh c&aacute;c luật,
            quy định hiện h&agrave;nh v&agrave; c&aacute;c th&ocirc;ng lệ Kinh
            doanh. C&aacute;c y&ecirc;u cầu hợp đồng v&agrave; c&aacute;c
            y&ecirc;u cầu ph&aacute;p l&yacute; kh&aacute;c, chẳng hạn như
            th&ocirc;ng b&aacute;o lưu giữ do Bộ phận ph&aacute;p l&yacute;
            ph&aacute;t h&agrave;nh, ch&uacute;ng t&ocirc;i c&oacute; thể sửa
            đổi c&aacute;c khoảng thời gian lưu giữ n&agrave;y. Hồ sơ Kinh doanh
            phải được lưu giữ trong hệ thống kho lưu trữ được The Thanh
            ph&ecirc; duyệt trong thời gian lưu giữ.&nbsp;
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <span style='font-size:19px;line-height:150%;'>
              Tiến h&agrave;nh chuyến c&ocirc;ng t&aacute;c một c&aacute;ch an
              to&agrave;n, hiệu quả nhất&nbsp;
            </span>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            Mặc d&ugrave; c&ocirc;ng t&aacute;c l&agrave; một phần cần thiết
            trong hoạt động Kinh doanh của The Thanh Coffee, nhưng n&oacute; thể
            hiện cam kết về thời gian v&agrave; chi ph&iacute; ch&iacute;nh của
            c&ocirc;ng ty. Ch&uacute;ng t&ocirc;i chịu tr&aacute;ch nhiệm
            đ&aacute;nh gi&aacute; mọi chuyến đi để đảm bảo chi ph&iacute; hợp
            l&yacute;, tu&acirc;n thủ c&aacute;c quy định của The Thanh
            v&agrave; quốc gia địa phương v&agrave; c&oacute; c&aacute;c
            ph&ecirc; duyệt cần thiết. Thay v&igrave; đi c&ocirc;ng t&aacute;c,
            h&atilde;y xem x&eacute;t c&aacute;c lựa chọn thay thế bằng
            c&aacute;c phương thức trực tuyến, chẳng hạn như cuộc gọi hội nghị,
            hội nghị tr&ecirc;n web, cuộc họp trực tuyến v&agrave; c&aacute;c
            c&ocirc;ng nghệ cộng t&aacute;c kh&aacute;c nếu c&oacute; thể.
            Ch&uacute;ng t&ocirc;i c&oacute; tr&aacute;ch nhiệm tu&acirc;n thủ
            luật nhập cư của c&aacute;c địa điểm m&agrave; ch&uacute;ng
            t&ocirc;i đến thăm khi tiến h&agrave;nh hoạt động Kinh doanh. Việc
            kh&ocirc;ng tu&acirc;n thủ luật nhập cư c&oacute; thể g&acirc;y ra
            những hậu quả rất nghi&ecirc;m trọng ảnh hưởng đến mỗi người trong
            ch&uacute;ng ta, The Thanh Coffee v&agrave; danh tiếng của The Thanh
            cũng như kh&aacute;ch h&agrave;ng của ch&uacute;ng ta.
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <span style='font-size:19px;line-height:150%;'>
              Mua sắm h&agrave;ng ho&aacute; v&agrave; dịch vụ&nbsp;
            </span>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            Ch&uacute;ng t&ocirc;i mua h&agrave;ng h&oacute;a v&agrave; dịch vụ
            dựa tr&ecirc;n năng lực v&agrave; kinh nghiệm của nh&agrave; thầu
            v&agrave; đảm bảo rằng nh&acirc;n vi&ecirc;n nh&agrave; cung cấp
            (bao gồm cả nh&agrave; thầu) cung cấp dịch vụ cho The Thanh Coffee
            v&agrave; kh&aacute;ch h&agrave;ng của ch&uacute;ng t&ocirc;i
            tu&acirc;n theo c&aacute;c y&ecirc;u cầu v&agrave; thủ tục về cam
            kết, tu&acirc;n thủ v&agrave; đảm bảo ti&ecirc;u chuẩn.&nbsp;
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <em>
              <span style='font-size:16px;line-height:150%;'>
                Mua h&agrave;ng h&oacute;a v&agrave; dịch vụ dựa tr&ecirc;n năng
                lực v&agrave; th&agrave;nh t&iacute;ch.&nbsp;
              </span>
            </em>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            Ch&uacute;ng t&ocirc;i l&agrave; những người mua cẩn thận. The Thanh
            Coffee mua h&agrave;ng h&oacute;a v&agrave; dịch vụ dựa tr&ecirc;n
            gi&aacute; cả, chất lượng, hiệu suất v&agrave; t&iacute;nh
            ph&ugrave; hợp. Ch&uacute;ng t&ocirc;i kh&ocirc;ng mua từ
            kh&aacute;ch h&agrave;ng của ch&uacute;ng t&ocirc;i chỉ v&igrave; họ
            giao dịch v&agrave; sử dụng dịch vụ từ ch&uacute;ng t&ocirc;i. The
            Thanh Coffee đ&atilde; ph&acirc;n c&ocirc;ng người ra quyết định mua
            h&agrave;ng, thanh to&aacute;n h&oacute;a đơn v&agrave; k&yacute;
            hợp đồng với nh&agrave; cung cấp. Sử dụng c&aacute;c quy
            tr&igrave;nh ti&ecirc;u chuẩn v&agrave; c&aacute;c hợp đồng
            nh&agrave; cung cấp ưu ti&ecirc;n đ&atilde; được tổ chức mua sắm của
            ch&uacute;ng t&ocirc;i đưa ra. Khi tham gia c&aacute;c thỏa thuận
            cung cấp mới, h&atilde;y c&acirc;n bằng hợp l&yacute; giữa
            gi&aacute; trị v&agrave; rủi ro. Khi quyết định trao c&ocirc;ng
            việc, h&atilde;y xem x&eacute;t hiệu suất về m&ocirc;i trường, đạo
            đức v&agrave; t&iacute;nh đa dạng của nh&agrave; cung cấp. Thực hiện
            theo c&aacute;c hướng dẫn v&agrave; nhận được sự chấp thuận cho việc
            thu&ecirc; L&atilde;nh đạo The Thanh Coffee cho c&aacute;c dịch vụ
            theo hợp đồng. Tr&aacute;nh c&aacute;c giao dịch c&oacute; vấn đề.
            Tr&aacute;nh c&aacute;c giao dịch c&oacute; vẻ kh&ocirc;ng
            ph&ugrave; hợp hoặc bất hợp ph&aacute;p hoặc c&oacute; thể
            tr&igrave;nh b&agrave;y sai c&aacute;c b&aacute;o c&aacute;o hoặc
            kết quả kế to&aacute;n của bất kỳ b&ecirc;n n&agrave;o trong giao
            dịch.
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            Đảm bảo rằng nh&acirc;n vi&ecirc;n nh&agrave; cung cấp (bao gồm cả
            nh&agrave; thầu) cung cấp dịch vụ cho The Thanh Coffee v&agrave;
            kh&aacute;ch h&agrave;ng của ch&uacute;ng t&ocirc;i tu&acirc;n theo
            c&aacute;c y&ecirc;u cầu v&agrave; thủ tục về cam kết, tu&acirc;n
            thủ v&agrave; r&uacute;t lui. Ch&uacute;ng t&ocirc;i tu&acirc;n theo
            c&aacute;c ti&ecirc;u chuẩn cao của c&aacute;c nh&agrave; cung cấp.
            C&aacute;c nh&agrave; cung cấp của ch&uacute;ng t&ocirc;i phải
            h&agrave;nh động theo c&aacute;ch ph&ugrave; hợp với Ti&ecirc;u
            chuẩn Ứng xử d&agrave;nh cho Nh&agrave; cung cấp của The Thanh
            Coffee, được x&acirc;y dựng dựa tr&ecirc;n c&aacute;c gi&aacute; trị
            cốt l&otilde;i của The Thanh Coffee v&agrave; ủng hộ c&aacute;c
            phương thức Kinh doanh c&oacute; tr&aacute;ch nhiệm.
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <span style='font-size:19px;line-height:150%;'>
              Điều tra, Tố tụng v&agrave; Kiểm to&aacute;n&nbsp;
            </span>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            Ch&uacute;ng t&ocirc;i tu&acirc;n thủ v&agrave; hợp t&aacute;c đầy
            đủ với c&aacute;c cuộc điều tra, kiện tụng v&agrave; kiểm
            to&aacute;n; ch&uacute;ng t&ocirc;i để c&aacute;c chuy&ecirc;n gia
            điều tra v&agrave; chỉ l&agrave;m việc với những người được ủy
            quyền; v&agrave; ch&uacute;ng t&ocirc;i x&aacute;c định v&agrave;
            lưu giữ th&ocirc;ng tin li&ecirc;n quan v&agrave; bảo vệ th&ocirc;ng
            tin li&ecirc;n lạc b&iacute; mật.&nbsp;
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <em>
              <span style='font-size:16px;line-height:150%;'>
                Tu&acirc;n thủ v&agrave; hợp t&aacute;c đầy đủ với c&aacute;c
                cuộc điều tra, kiện tụng v&agrave; kiểm to&aacute;n&nbsp;
              </span>
            </em>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            Bất cứ khi n&agrave;o bạn tham gia v&agrave;o c&aacute;c cuộc điều
            tra nội bộ hoặc ch&iacute;nh phủ, kiện tụng hoặc kiểm to&aacute;n
            đang diễn ra, h&atilde;y hợp t&aacute;c đầy đủ với c&aacute;c hướng
            dẫn điều tra v&agrave; n&oacute;i to&agrave;n bộ sự thật.&nbsp;
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <em>
              <span style='font-size:16px;line-height:150%;'>
                Cho ph&eacute;p c&aacute;c chuy&ecirc;n gia điều tra v&agrave;
                chỉ l&agrave;m việc với nh&acirc;n vi&ecirc;n được ủy
                quyền&nbsp;
              </span>
            </em>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            Kh&ocirc;ng bao giờ tự m&igrave;nh tiến h&agrave;nh điều tra &mdash;
            tất cả c&aacute;c y&ecirc;u cầu tiến h&agrave;nh điều tra nội bộ
            phải được b&aacute;o c&aacute;o v&agrave; được sự cho ph&eacute;p
            của Điều tra C&ocirc;ng ty hoặc Cơ quan Bảo vệ T&agrave;i sản. Chỉ
            l&agrave;m việc với nh&acirc;n vi&ecirc;n Ph&aacute;p l&yacute; được
            ủy quyền để trả lời c&aacute;c vụ kiện tụng hoặc tr&aacute;t
            đ&ograve;i hầu t&ograve;a (bao gồm cả y&ecirc;u cầu của kh&aacute;ch
            h&agrave;ng) hoặc c&aacute;c y&ecirc;u cầu từ ch&iacute;nh phủ, cơ
            quan thực thi ph&aacute;p luật, kiểm to&aacute;n vi&ecirc;n
            b&ecirc;n ngo&agrave;i hoặc cơ quan quản l&yacute;.&nbsp;
          </span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <strong>
            <em>
              <span style='font-size:16px;line-height:150%;'>
                X&aacute;c định v&agrave; bảo quản th&ocirc;ng tin li&ecirc;n
                quan v&agrave; bảo vệ th&ocirc;ng tin li&ecirc;n lạc b&iacute;
                mật&nbsp;
              </span>
            </em>
          </strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;'>
          <span style='font-size:16px;line-height:150%;'>
            Hỗ trợ việc x&aacute;c định, thu thập v&agrave; bảo quản t&agrave;i
            liệu, dữ liệu v&agrave; th&ocirc;ng tin li&ecirc;n quan đến điều
            tra, tố tụng v&agrave; kiểm to&aacute;n. Kh&ocirc;ng bao giờ che
            giấu, thay đổi hoặc ti&ecirc;u hủy t&agrave;i liệu, dữ liệu hoặc
            th&ocirc;ng tin khi bạn biết hoặc biết trước một cuộc điều tra, kiện
            tụng hoặc kiểm to&aacute;n. Giữ b&iacute; mật tuyệt đối tất cả
            c&aacute;c th&ocirc;ng tin được trao đổi trong qu&aacute;
            tr&igrave;nh điều tra, tố tụng hoặc kiểm to&aacute;n.
          </span>
        </p>`,
                    }}
                ></Box>
            </Layout.MainBg>
        </Box>
    );
};

export default Mobile;
