import React from "react";
import { styles } from "../../../styles";
import { Box } from "@mui/material";
const VIconDesktop = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.7956 40.4398L43.5121 20.7233L40.9629 18.2195L23.7956 35.4152L15.1305 26.7501L12.6544 29.2539L23.7956 40.4398ZM28.1165 55.9078C24.3021 55.9078 20.708 55.1783 17.3344 53.7192C13.9608 52.2601 11.0107 50.2678 8.48418 47.7422C5.95762 45.2167 3.96432 42.2678 2.50428 38.8956C1.04472 35.524 0.314941 31.9309 0.314941 28.1165C0.314941 24.2718 1.04448 20.658 2.50355 17.2751C3.96262 13.8922 5.95494 10.9497 8.48052 8.44754C11.0061 5.94537 13.955 3.96426 17.3271 2.50422C20.6988 1.04466 24.2918 0.31488 28.1063 0.31488C31.951 0.31488 35.5648 1.04441 38.9476 2.50349C42.3305 3.96256 45.273 5.94269 47.7752 8.44388C50.2774 10.9451 52.2585 13.8864 53.7185 17.2678C55.1781 20.6492 55.9079 24.262 55.9079 28.1062C55.9079 31.9207 55.1783 35.5147 53.7193 38.8883C52.2602 42.2619 50.2801 45.212 47.7789 47.7386C45.2777 50.2651 42.3364 52.2584 38.955 53.7185C35.5735 55.178 31.9607 55.9078 28.1165 55.9078ZM28.1114 52.5876C34.9235 52.5876 40.7059 50.2039 45.4586 45.4366C50.2113 40.6688 52.5877 34.8937 52.5877 28.1113C52.5877 21.2993 50.2113 15.5169 45.4586 10.7642C40.7059 6.01145 34.9235 3.6351 28.1114 3.6351C21.3291 3.6351 15.554 6.01145 10.7862 10.7642C6.01883 15.5169 3.63516 21.2993 3.63516 28.1113C3.63516 34.8937 6.01883 40.6688 10.7862 45.4366C15.554 50.2039 21.3291 52.5876 28.1114 52.5876Z"
          fill="#006241"
        />
      </svg>
    </Box>
  );
};

export default VIconDesktop;
